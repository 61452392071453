<template>
  <div>
    <br />

    <div align="center">
      <div class="blue box ex2">
        <div class="coral item">
          <span class="pink item">
            <feather-icon
              v-b-toggle.sidebar-1
              icon="AlignJustifyIcon"
              size="38"
            />
          </span>
        </div>
        <div class="coral item"></div>
      </div>
      <div class="blue box ex1">
        <div class="coral item">
          <h3 style="margin-top: -30px"><b>หน้าแรก</b></h3>
        </div>
      </div>

      <hr />
      <b-avatar
        size="60"
        :src="logo"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
      </b-avatar>
      <span
        ><b
          ><h3>{{ store_name }}</h3></b
        ></span
      >
      <b-row style="width: 400px"
        ><br />
        <b-col cols="6" style="border-right: 1px solid black">
          <div align="center">{{items.wifi_users}}</div>
          <div align="center">เพื่อนจากระบบ WiFi</div>
        </b-col>
        <b-col cols="6" style="border-left: 1px solid black">
          <div align="center">{{ items.fetchURL.followers }}</div>
          <div align="center">เพื่อนทั้งหมด</div>
        </b-col> </b-row
      ><br />
      <div id="btn_login_line" @click="quickPosts()">สร้างแบรนด์ แคมเปญ</div>
      <br />

      <div class="blue box ex4">
        <div class="coral item">
          <div @click="BrandPages()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50px"
              height="50px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-tv"
            >
              <rect x="2" y="7" width="20" height="15" rx="2" ry="2"></rect>
              <polyline points="17 2 12 7 7 2"></polyline>
            </svg>
            <br />
            ตั้งค่าแบรนด์ เพจ
          </div>
        </div>
        <div class="coral item">
          <div @click="quickPosts()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50px"
              height="50px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-target"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <circle cx="12" cy="12" r="6"></circle>
              <circle cx="12" cy="12" r="2"></circle>
            </svg>
            <br />
            แบรนด์ แคมเปญ
          </div>
        </div>
        <div class="coral item">
          <div @click="splash()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50px"
              height="50px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-airplay"
            >
              <path
                d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"
              ></path>
              <polygon points="12 15 17 21 7 21 12 15"></polygon>
            </svg>
            <br />
            Wi-Fi แคมเปญ
          </div>
        </div>
      </div>
    </div>

    <b-sidebar id="sidebar-1" shadow bg-variant="white" backdrop>
      <div style="left: 2" class="ml-3">
        <div style="margin-top: 0x">
          <h3>รายชื่อบัญชี</h3>
        </div>

        <div class="blue box ex4">
          <div class="coral item">
            <b-avatar size="40" :src="logo" variant="light-primary"> </b-avatar>
          </div>
          <div class="coral item" style="margin-left: -160px">
            <span>{{ store_name }}</span>
          </div>
        </div>
        <hr />
        <div v-for="(store, index) in data_stores" :key="store.id">
          <div v-if="index > 3">
            <div
              align="center"
              style="color: #3ac659"
              v-if="index == 4 && show_all == false"
              @click="show_all = true"
            >
              แสดงทั้งหมด
              <hr />
            </div>

            <div
              v-if="show_all"
              @click="
                setstore(
                  store.pictrue,
                  store.id,
                  store.store_name,
                  store.business_type,
                  store.package,
                  store.user_id,
                  store.line_oa
                )
              "
            >
              <div class="blue box ex4">
                <div class="coral item">
                  <b-avatar
                    size="40"
                    :src="store.line_oa ? store.line_oa.logo : null"
                    variant="light-primary"
                  >
                  </b-avatar>
                </div>
                <div class="coral item" style="margin-left: -160px">
                  <span>{{ store.store_name }}</span>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div
            v-else
            @click="
              setstore(
                store.pictrue,
                store.id,
                store.store_name,
                store.business_type,
                store.package,
                store.user_id,
                store.line_oa
              )
            "
          >
            <div class="blue box ex4">
              <div class="coral item">
                <b-avatar
                  size="40"
                  :src="store.line_oa ? store.line_oa.logo : null"
                  variant="light-primary"
                >
                </b-avatar>
              </div>
              <div class="coral item" style="margin-left: -160px">
                <span>{{ store.store_name }}</span>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import liff from "@line/liff";
import api from "@/api";
import useJwt from "@/auth/jwt/useJwt";

import {
  BRow,
  BCol,
  BAvatar,
  BSidebar,
  BButton,
  VBToggle,
} from "bootstrap-vue";

export default {
  components: { BRow, BCol, BAvatar, BSidebar, BButton },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      store_name: localStorage.getItem("store_name"),
      logo: localStorage.getItem("logo"),
      data_profile: "",
      lineID: "",
      name: "",
      email: "",
      picture: "",
      currentUrl: "",
      data: [],
      product: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      data_stores: "",
      show_all: false,
      items: { fetchURL: { followers: "กำลังโหลด..." } ,wifi_users:'กำลังโหลด...'},
    };
  },
  // created() {
  //   if (
  //     localStorage.getItem("vuexy-skin") !== "light" ||
  //     !localStorage.getItem("vuexy-skin")
  //   ) {
  //     localStorage.setItem("vuexy-skin", "light");
  //     window.location.reload();
  //   }
  //   this.currentUrl = window.location.origin;
  //   if (this.$route.query.source) {
  //     this.get_transaction(this.$route.query.source);
  //   } else {
  //     liff
  //       .init({ liffId: "1657714117-GaYL0d0a" })
  //       .then(() => {
  //         // console.log("then :>> ");
  //         // console.log("liff.isLoggedIn() :>> ", liff.isLoggedIn());
  //         if (!liff.isLoggedIn()) {
  //           liff.login();
  //         } else {
  //           liff.getFriendship().then((data) => {
  //             if (data.friendFlag) {
  //               // console.log("data.friendFlag :>> ", data.friendFlag);
  //               // console.log("เปงงงงงกัง! เพื่อนกันยุแว้วว");
  //               this.data_profile = liff.getDecodedIDToken();
  //               // console.log("this.data_profile :>> ", this.data_profile);
  //               this.lineID = this.data_profile.sub;
  //               this.name = this.data_profile.name;
  //               this.email = this.data_profile.email;
  //               this.picture = this.data_profile.picture;
  //               const params = {
  //                 email: this.email,
  //                 password: "wifi" + this.lineID + "socio",
  //               };

  //               localStorage.setItem("data_profile", JSON.stringify(this.data_profile));
  //               api.post("/login", params).then((response) => {
  //                 // console.log("response.data :>> ", response.data);
  //                 useJwt.setToken(response.data.accessToken);
  //                 useJwt.setRefreshToken(response.data.refreshToken);
  //                 localStorage.setItem("userData", JSON.stringify(response.data));

  //                 this.$ability.update(response.data.ability);
  //                 // this.$router.push("/liff/BrandPages");
  //                 this.name_user = JSON.parse(localStorage.getItem("userData"));
  //                 console.log(" this.name_user:>> ", this.name_user);
  //                 const params = {
  //                   id: this.name_user.user_main,
  //                 };

  //                 api
  //                   .post("getstore", params, {
  //                     headers: {
  //                       Authorization: "Bearer " + localStorage.getItem("accessToken"),
  //                       "Content-Type": "application/json",
  //                     },
  //                   })
  //                   .then((response) => {
  //                     console.log("response.data.stores :>> ", response.data.stores);
  //                   })
  //                   .catch((error) => {
  //                     // window.location.reload();
  //                   });
  //               });
  //             } else {
  //               console.log("data.friendFlag :>> ", data.friendFlag);

  //               var url =
  //                 "https://access.line.me/oauth2/v2.1/authorize?client_id=1657484988&response_type=code&redirect_uri=https://manager.socio.co.th/liff/auth&logintype=web&scope=openid%20profile%20email&state=XXX&nonce=09876xyz&auth0&bot_prompt=aggressive&prompt=consent";
  //               console.log("url :>> ", url);
  //               window.location.href = url;
  //             }
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // },

  mounted() {
    this.loadstore();
    this.loaddata();
  },
  methods: {
    setstore(
      pictrue,
      id,
      store_name,
      business_type,
      package_stroe,
      user_id,
      lineoa
    ) {
      console.log("id :>> ", id);
      console.log("pictrue :>> ", pictrue);
      console.log("store_name :>> ", store_name);
      console.log("business_type :>> ", business_type);
      console.log("package_stroe :>> ", package_stroe);
      console.log("user_id :>> ", user_id);
      localStorage.setItem("store_id", id);
      localStorage.setItem("store_name", store_name);
      localStorage.setItem("logo", lineoa ? lineoa.logo : null);
      window.location.reload();
    },

    async loadstore() {
      var userData = JSON.parse(localStorage.getItem("userData"));
      //  console.log('userData :>> ', userData);
      const params = {
        user_main: userData.user_main,
      };
      api
        .post("get_store_switch", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("get_store_switch response.data :>> ", response.data);
          this.data_stores = response.data.users;
          
        })
        .catch((error) => {});
    },
    loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      var access_token = localStorage.getItem("accessToken");
      api
        .post("check_connect_oa", params, {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.items = response.data.data[0];

          console.log("this.items :>> ", this.items);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    quickPosts() {
      this.$router.push("/liff/quickPosts/create");
    },
    BrandPages() {
      this.$router.push("/liff/BrandPages");
    },
    splash() {
      this.$router.push("/liff/SplashPage");
    },
  },
};
</script>

<style lang="scss" scoped>
#btn_login_line {
  padding-top: 15px;
  margin-top: 20px;
  color: #fff;
  background-color: #ff8a00 !important;
}
</style>
